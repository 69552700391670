.message {
   text-align: start;
   border-radius: 3px;
}

.first-user {
   color: #fff;
   background-color: #4870df;
   width: fit-content;
}
.second-user {
   background-color: #fafafa;
   box-shadow: 0px 8px 5px -9px rgba(0, 0, 0, 0.08);
}
.card {
   box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.08);
}
