.svg-image {
   width: 100%;
   height: 35rem;
}
.svg-image path:hover {
   fill: rgb(255, 140, 0);
}

/* Add styles for the highlighted part of the image */
/* Example:
  .rect:hover {
    fill: yellow;
  }
  
  .circle:hover {
    fill: blue;
  }
  */

.table-container {
   height: 7rem !important;
   overflow-y: scroll;
}
.highlighted-table {
   width: 100%;
   border-collapse: collapse;
}

.highlighted-table td {
   border: 1px solid #ccc;
   padding: 8px;
}

.highlighted {
   background-color: rgb(255, 140, 0);
}

.image-container div {
   display: block !important;
   cursor: pointer;
}
